// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';
@media (min-height: 600px){
    .tables-container{
        height: 480px;
    }
}
@media (min-height: 1000px){
    .tables-container{
        height: 900px;
    }
}
.tab-color{
    width: 10px;
    height: 29px;
    position: absolute;
    left: 0;
    top: 0;
}
.table{
    font-size: 12px;
}
.search-area{
    width: 400px !important;
}